<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addReturnTo } from '@@/utils/LoginUtils';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';
import { useLoginView } from '@@/composables/useLoginView';

const loginStore = useLoginStore();

const { $loading, $toast } = useNuxtApp();
const route = useRoute();
const router = useRouter();

const {
  isLoginEnterEmailView,
  isLoginEnterPasswordView,
  isForgotPasswordEnterEmailView,
  isForgotPasswordEnterPasswordView,
  isForgotPasswordLinkSentView,
  isLoginLinkSentView,
  isRegisterLinkSentView,
  isRegisterEnterEmailView,
  isChangeEmailLinkSentView,
  isDeleteAccountLinkSentView,
} = useLoginView();
const { registerButtonText } = useDefaultTrial();

const buttons = computed(() => {
  const buttons = [];

  /* eslint no-script-url: off */
  const getDefaultButton = () => ({
    click: () => {},
    mousedown: () => {},
  });

  const registerButton = {
    ...getDefaultButton(),
    text: registerButtonText.value,
    to: '/user/register?source=signup',
    type: ButtonTypes.secondary,
  };

  const loginButton = {
    ...getDefaultButton(),
    text: 'Log In',
    to: '/user/login',
    type: ButtonTypes.secondary,
  };

  const loginInsteadButton = {
    ...getDefaultButton(),
    text: 'Already have an account? Log In',
    to: '/user/login',
    type: ButtonTypes.secondary,
  };

  const loginWithEmailButton = {
    ...getDefaultButton(),
    click: () => sendEmail(),
    text: 'Login with Email',
    type: ButtonTypes.secondary,
  };

  const forgotPasswordButton = {
    ...getDefaultButton(),
    text: 'Forgot your password?',
    to: '/user/forgotpassword',
    type: ButtonTypes.secondary,
  };

  const resendEmailButton = {
    ...getDefaultButton(),
    click: () => sendEmail(true),
    text: 'Resend Email',
    type: ButtonTypes.secondary,
  };

  const startOverButton = {
    ...getDefaultButton(),
    click: () => startOver(),
    text: 'Start Over',
    type: ButtonTypes.secondary,
  };

  if (isLoginEnterEmailView.value) {
    buttons.push(registerButton, forgotPasswordButton);
  }
  else if (isLoginEnterPasswordView.value) {
    buttons.push(loginWithEmailButton, forgotPasswordButton);
  }
  else if (isForgotPasswordEnterEmailView.value) {
    buttons.push(registerButton, loginButton);
  }
  else if (isForgotPasswordEnterPasswordView.value) {
    buttons.push(registerButton, startOverButton);
  }
  else if (isForgotPasswordLinkSentView.value
    || isLoginLinkSentView.value
    || isRegisterLinkSentView.value) {
    buttons.push(resendEmailButton, startOverButton);
  }
  else if (isRegisterEnterEmailView.value) {
    buttons.push(loginInsteadButton);
  }
  else if (isChangeEmailLinkSentView.value || isDeleteAccountLinkSentView.value) {
    buttons.push(resendEmailButton);
  }

  if (buttons.length === 2) {
    buttons.forEach((button) => {
      button.additionalClassNames = 'tw-flex-grow tw-mb-4 last:tw-mb-0 lg:tw-grow-0 lg:tw-w-1/2 lg:tw-mb-0 lg:first:tw-mr-2 lg:last-tw-ml-2';
    });
  }
  else {
    buttons[0].additionalClassNames = 'tw-flex-grow';
  }

  buttons.forEach((button) => {
    if (button.to) {
      button.to = addReturnTo(button.to, route.query);
    }
  });

  return buttons;
});

const sendEmail = async (resend = false) => {
  const handleError = () => $toast.open({
    message: `Error! Unable to ${resend ? 'resend' : 'send'} link.`,
    type: 'error',
  });

  try {
    let linkSent;

    $loading.start();

    if (isForgotPasswordLinkSentView.value) {
      linkSent = await loginStore.makePasswordLinkRequest();
    }
    else if (isRegisterLinkSentView.value) {
      linkSent = await loginStore.makeRegisterLinkRequest();
    }
    else if (isChangeEmailLinkSentView.value) {
      linkSent = await loginStore.makeSettingsEmailLinkRequest();
    }
    else if (isDeleteAccountLinkSentView.value) {
      linkSent = await loginStore.makeSettingsDeleteLinkRequest();
    }
    else {
      const { return_to } = route.query;
      const payload = { return_to };

      linkSent = await loginStore.makeLoginLinkRequest(payload);
    }

    if (!linkSent) {
      handleError();
    }
    else if (resend) {
      $toast.open({
        message: 'Success! Please check your email for your new link.',
        type: 'success',
      });
    }
    else {
      loginStore.setLoginView(loginViews.loginLinkSent);
    }
  }
  catch (e) {
    handleError();
  }

  $loading.finish();
};

const startOver = () => {
  if (isForgotPasswordEnterPasswordView.value) {
    router.push('/user/forgotpassword');
    return;
  }

  if (isForgotPasswordLinkSentView.value) {
    loginStore.setLoginView(loginViews.forgotPasswordEnterEmail);
  }
  else if (isRegisterLinkSentView.value) {
    loginStore.setLoginView(loginViews.registerEnterEmail);
  }
  else if (isDeleteAccountLinkSentView.value) {
    loginStore.setLoginView(loginViews.deleteAccount);
  }
  else {
    loginStore.setLoginView(loginViews.loginEnterEmail);
  }

  loginStore.setCredentials({ email: null, passcode_validate: null, password: null });
};
</script>

<template>
  <Card
    :has-body-padding-responsive="true"
  >
    <template #body>
      <div class="tw-flex tw-flex-col lg:tw-flex-row">
        <Button
          v-for="button in buttons"
          :key="button.text"
          :additional-class-names="button.additionalClassNames"
          :to="button.to"
          :type="button.type"
          @click="button.click"
          @mousedown="button.mousedown"
        >
          {{ button.text }}
        </Button>
      </div>
    </template>
  </Card>
</template>
