import { loginViews, useLoginStore } from '@@/stores/Login';

export const useLoginView = () => {
  const loginStore = useLoginStore();

  // For each loginView return a computed property named, "isLoginView", that checks if
  // loginStore.loginView is equal to the specified loginView. e.g.:
  //
  // {
  //   isChangeEmailEnterEmailView: computed(() => loginStore.loginView === loginViews.changeEmailEnterEmail),
  //   isChangeEmailLinkSentView: computed(() => loginStore.loginView === loginViews.changeEmailLinkSentView),
  //   ...
  // }

  return Object
    .keys(loginViews)
    .reduce((acc, loginView) => {
      const loginViewCapitalized = loginView.charAt(0).toUpperCase() + loginView.slice(1);
      const computedPropertyName = `is${loginViewCapitalized}View`;
      acc[computedPropertyName] = computed(() => loginStore.loginView === loginView);
      return acc;
    }, {});
};
